.yellowButton:hover {
  background-color: #f4b042 !important;
}

.navigationLinkButton:hover {
  text-decoration: underline;
}

.button {
  opacity: 0;
}
.button:hover {
  opacity: 1;
}

.greenButton:hover {
  box-shadow: 0 1px 0 0 #14a487;
}
.outlineButton:hover {
  background-color: rgb(242, 242, 242) !important;
}

.greenUnderlineButton:hover {
  text-decoration: underline !important;
}

.arrowButton:hover {
  background-color: var(--mantine-color-primaryPink-2) !important;
}
.arrowButton:hover svg * {
  stroke: white !important;
}
