.select {
  width: 100%;
  color: "black";
  background: "white";
  border-radius: 100px;
  font-family: "Inter";
  border: "0.5px solid #000";
}

.select > button {
  text-align: center;
  padding-block: 10px;
  padding-inline: 20px;
  gap: 0px;
  font-size: 16px;
  color: #000;
  display: flex;
  font-family: "Inter";
  border: 0.5px solid #000;
  border-radius: 100px;
  outline: none;
}

@media (max-width: 380px) {
  .select > button {
    padding-block: 5.5px;
  }
  /* .select > button > span {
    font-size: 12px;
  } */
}
@media (max-width: 320px) {
  .select > button {
    padding-block: 5.5px;
  }
  /* .select > button > span {
    font-size: 12px;
  } */
}

@media (min-width: 1024px) {
  .select > button {
    padding-inline: 20px;
  }
}

.select > ul {
  border-radius: 14px;
}

.select li {
  padding-inline: 16px !important;
}
.select input {
  border-radius: 100px;
  padding-left: 16px !important;
  border: 0.5px solid gray;
}

.select > button:disabled {
  background: #efefef;
  cursor: no-drop;
}
.inputField:disabled {
  background-color: #efefef !important;
  cursor: no-drop;
}
